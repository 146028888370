import React from 'react';
import './Services.scss'; // Import the SCSS file for styling

const Services = () => {
  return (
    <section className="services-section">
      <div className="services-header">
        <h2>AI-Powered Services to Automate Your Business</h2>
        <p>
          ApaxonCore provides tailored automation solutions for various business functions, enhancing productivity and operational efficiency.
        </p>
      </div>

      {/* Services Grid */}
      <div className="services-grid">
        <div className="service-card">
          <div className="service-icon">💼</div> {/* Placeholder for Marketing icon */}
          <h3>Marketing</h3>
          <p>
            Automate marketing campaigns, lead generation, and analytics with AI to streamline your marketing efforts.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">👥</div> {/* Placeholder for HR icon */}
          <h3>Human Resources</h3>
          <p>
            Simplify recruitment, onboarding, and employee management by automating HR tasks with AI solutions.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">📊</div> {/* Placeholder for Accounting icon */}
          <h3>Accounting</h3>
          <p>
            Automate financial reporting, expense tracking, and payroll management to ensure accuracy and reduce errors.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">💻</div> {/* Placeholder for IT Support icon */}
          <h3>IT Support</h3>
          <p>
            Enhance IT support with AI-driven troubleshooting, ticketing, and automated maintenance solutions.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">📈</div> {/* Placeholder for Sales icon */}
          <h3>Sales</h3>
          <p>
            Automate lead management, follow-ups, and sales analytics to boost your sales team's performance.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">🔄</div> {/* Placeholder for Operations icon */}
          <h3>Operations</h3>
          <p>
            Streamline operational workflows, project management, and resource allocation with intelligent automation.
          </p>
        </div>

        <div className="service-card">
          <div className="service-icon">📞</div> {/* Placeholder for Customer Service icon */}
          <h3>Customer Service</h3>
          <p>
            Provide 24/7 customer support with AI-powered chatbots and automated ticketing systems.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
