import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss'; // Import the corresponding SCSS file for styling

const Header = () => {
  return (
    <>
      {/* Top Bar */}
      <div className="topbar">
        <div className="topbar-content">
          <span>📍 123 Business St, City</span>
          <span>📞 +1 234 567 890</span>
          <span>✉️ contact@apaxoncore.com</span>
        </div>
      </div>

      {/* Main Navigation Bar */}
      <header className="navbar">
        <div className="navbar-container">
          {/* Logo */}
          <div className="navbar-logo">
            <img src="/assets/images/logo.png" alt="ApaxonCore Logo" />
          </div>

          {/* Navigation Links */}
          <nav className="navbar-menu">
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/resources">Resources</Link>
            <Link to="/solutions">Solutions</Link>
            <Link to="/contact-us">Contact Us</Link>
          </nav>

          {/* Right Side - Actions */}
          <div className="navbar-actions">
            <Link to="/host" className="btn become-host">Become a Host</Link>
            <button className="btn language-selector">🌐</button>
            <button className="btn user-profile">
              <span>👤</span>
              <span className="notification-dot"></span>
            </button>
            <button className="btn menu-icon">≡</button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
