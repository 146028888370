import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import CTA from '../components/CTA/CTA';
import About from '../components/About/About';
import Services from '../components/Services/Services';
import Industries from '../components/Industries/Industries';
import './HomePage.scss';  // Custom SCSS for the homepage

const HomePage = () => {
  return (
    <div className="homepage">
      {/* Header Section */}
      <Header />

      {/* Hero Section */}
      <section className="hero-section">
        <Hero />
      </section>

      {/* About Us Section */}
      <section className="about-section">
        <About />
      </section>

      {/* Services Section */}
      <section className="services-section">
        <Services />
      </section>

      {/* Industry Use Cases Section */}
      <section className="industries-section">
        <Industries />
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <CTA />
      </section>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default HomePage;
