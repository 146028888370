import React from 'react';
import './Industries.scss'; // Import the SCSS file for styling

const Industries = () => {
  return (
    <section className="industries-section">
      <div className="industries-header">
        <h2>Transform Every Industry with Automation</h2>
        <p>
          ApaxonCore enables automation for various industries, streamlining operations and driving efficiency across all business processes.
        </p>
      </div>

      {/* Industry Grid */}
      <div className="industries-grid">
        <div className="industry-card">
          <div className="industry-icon">🧑‍💼</div> {/* Placeholder for icon */}
          <h3>Human Resources</h3>
          <p>
            Automate recruitment, onboarding, and employee management to save time and enhance efficiency in HR operations.
          </p>
        </div>

        <div className="industry-card">
          <div className="industry-icon">📊</div> {/* Placeholder for icon */}
          <h3>Sales</h3>
          <p>
            Leverage automation to streamline sales processes, manage leads, and close deals faster with minimal manual intervention.
          </p>
        </div>

        <div className="industry-card">
          <div className="industry-icon">🏗️</div> {/* Placeholder for icon */}
          <h3>Construction</h3>
          <p>
            Automate project management, procurement, and scheduling to keep construction projects on time and on budget.
          </p>
        </div>

        <div className="industry-card">
          <div className="industry-icon">📦</div> {/* Placeholder for icon */}
          <h3>Logistics</h3>
          <p>
            Optimize supply chains, inventory management, and delivery logistics with tailored automation solutions.
          </p>
        </div>

        <div className="industry-card">
          <div className="industry-icon">💼</div> {/* Placeholder for icon */}
          <h3>Finance</h3>
          <p>
            Automate financial reporting, compliance, and transaction processing to ensure accuracy and reduce operational costs.
          </p>
        </div>

        <div className="industry-card">
          <div className="industry-icon">⚕️</div> {/* Placeholder for icon */}
          <h3>Healthcare</h3>
          <p>
            Streamline patient management, billing, and appointment scheduling to improve healthcare delivery and patient satisfaction.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Industries;
