import React from 'react';
import './Footer.scss'; // SCSS file for styling

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        {/* Left side - Call to action */}
        <div className="footer-cta">
          <h2>ApaxonCore helps you automate and grow your business faster.</h2>
          <a href="/start-free-trial" className="cta-btn">
            Start 14 Days Free Trial
          </a>
        </div>

        {/* Right side - Navigation Links */}
        <div className="footer-links">
          <div className="footer-column">
            <h3>Platform</h3>
            <ul>
              <li><a href="/about-us">About</a></li>
              <li><a href="/features">Features</a></li>
              <li><a href="/pricing">Pricing & Plans</a></li>
              <li><a href="/contact-us">Contact</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Resources</h3>
            <ul>
              <li><a href="/account">Account</a></li>
              <li><a href="/tools">Tools</a></li>
              <li><a href="/newsletter">Newsletter</a></li>
              <li><a href="/faq">FAQ</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Legals</h3>
            <ul>
              <li><a href="/guides">Guides</a></li>
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/licensing">Licensing</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/* Social media links */}
      <div className="footer-social">
        <p>Follow us on:</p>
        <div className="social-icons">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">🐦</a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">📘</a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">📷</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
