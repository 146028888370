import React from 'react';
import './CTA.scss'; // Corresponding SCSS for styling

const CTA = () => {
  return (
    <section className="cta-section">
      {/* Main heading */}
      <div className="cta-header">
        <h1>Get Proven Results with ApaxonCore</h1>
      </div>

      {/* Three main benefits */}
      <div className="cta-benefits">
        <div className="benefit">
          <h3>Automate Workflows Seamlessly</h3>
          <p>
            Leverage AI to streamline your business operations, saving time and resources.
          </p>
        </div>
        <div className="benefit">
          <h3>Unlock Business Insights</h3>
          <p>
            ApaxonCore provides powerful analytics that help you make data-driven decisions faster.
          </p>
        </div>
        <div className="benefit">
          <h3>Increase Operational Efficiency</h3>
          <p>
            Get access to tailored solutions that fit your business and improve daily operations.
          </p>
        </div>
      </div>

      {/* Final Call-to-Action */}
      <div className="cta-footer">
        <p>
          Don't miss out on the opportunity to revolutionize your business. Secure your spot today and transform how your team works.
        </p>
        <a href="/get-started" className="cta-btn">Get your free consultation</a>
      </div>
    </section>
  );
};

export default CTA;
