import React from 'react';
import HomePage from './pages/HomePage';
import './App.css';  // Import global styles (if necessary)

function App() {
  return (
    <div className="App">
      {/* Render the HomePage as the main content */}
      <HomePage />
    </div>
  );
}

export default App;

