import React from 'react';
import './About.scss'; // SCSS styling for this component

const About = () => {
  return (
    <section className="about-section">
      <div className="about-container">
        {/* Left Side: Image */}
        <div className="about-image">
          <img src="/assets/images/about-image.jpg" alt="ApaxonCore Team Member" />
          <div className="testimonial-card">
            <span role="img" aria-label="wave" className="wave-icon">👋</span>
            <p>
              "ApaxonCore revolutionized the way we handle automation. Our workflow is more efficient and saves us valuable time every day."
            </p>
          </div>
        </div>

        {/* Right Side: Content */}
        <div className="about-content">
          <h2>We make automation easy for businesses.</h2>
          <p>
            At ApaxonCore, we simplify business automation by offering a suite of AI-driven tools tailored to your needs. Our solutions help you manage your day-to-day operations effortlessly.
          </p>
          <p>
            From streamlining your HR processes to automating sales pipelines, ApaxonCore integrates with your existing infrastructure and transforms the way you work.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
