import React from 'react';
import './Hero.scss'; // Corresponding SCSS file for styling

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h2>YOUR BUSINESS DESERVES A BOOST</h2>
        <h1>Connect & automate your business workflows effortlessly</h1>
        <div className="cta-buttons">
          <a href="/free-trial" className="cta-btn primary-btn">Start 14 Days Free Trial</a>
          <a href="/contact-sales" className="cta-btn secondary-btn">Talk to Sales</a>
        </div>
      </div>

      {/* Visual representation (image grid or similar to reflect ApaxonCore's services or customers) */}
      <div className="hero-image-grid">
        <div className="image-box">
          <img src="/assets/images/customer1.jpg" alt="Customer 1" />
          <p>Alex Johnson</p>
        </div>
        <div className="image-box">
          <img src="/assets/images/customer2.jpg" alt="Customer 2" />
          <p>Emily White</p>
        </div>
        <div className="image-box">
          <img src="/assets/images/customer3.jpg" alt="Customer 3" />
          <p>Chris Lee</p>
        </div>
        <div className="image-box">
          <img src="/assets/images/customer4.jpg" alt="Customer 4" />
          <p>Sara Thomas</p>
        </div>
        <div className="image-box">
          <img src="/assets/images/customer5.jpg" alt="Customer 5" />
          <p>Michael Brown</p>
        </div>
        <div className="image-box">
          <img src="/assets/images/customer6.jpg" alt="Customer 6" />
          <p>Linda Green</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
